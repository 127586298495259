export default defineNuxtRouteMiddleware(async (to) => {
  const browserLanguage = process.client ? navigator.language : null;

  if (
    browserLanguage &&
    !browserLanguage.toLowerCase().includes("es") &&
    !to.fullPath.includes("/en")
  ) {
    return navigateTo(`/en${to.fullPath}`, { replace: true });
  }
});
